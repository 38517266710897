






































































































































































































import { uniq } from "lodash/fp";
import InputMask from "@/components/UI/InputMask.vue";
import { OrderItemInterface } from "@/models/order/OrderItem.interface";
import plural from "@/utils/plural";
import { computed, defineComponent, Ref, ref } from "vue";
import { useInsuranceCalc } from "@/components/order/compositions/useInsuranceCalc";
import useOrderCreation from "@/components/order/compositions/useOrderCreation";
import useVuelidate from "@vuelidate/core";
import OrderItemModel from "@/models/order/OrderItem.model";
import { required } from "@vuelidate/validators";
import TemplateOptions from "@/components/order/TemplateOptions.vue";
import { TemplateFormInterface } from "@/models/store/TemplatesTableHeader.interface";
import OptimalRoutes from "@/components/order/Route/Route.vue";
import BaseInput from "@/components/UI/BaseInput.vue";

export default defineComponent({
  name: "Order",
  components: {
    BaseInput,
    OptimalRoutes,
    OrderItemFtl: () => import("@/components/order/OrderItemFtl.vue"),
    OrderItemLtl: () => import("@/components/order/OrderItemLtl.vue"),
    InputMask,
    TemplateOptions
  },
  filters: {
    percent(value: number) {
      return value.toFixed(2);
    }
  },
  setup() {
    const form = ref<OrderItemInterface>(new OrderItemModel());

    const showSaveTemplateChoiceDialog = ref<boolean>(false);
    const showEditTemplateNameDialog = ref<boolean>(false);
    const templateNewId = ref<string | null>("");
    const templateNewName = ref<string>("");

    const vTemplate$ = useVuelidate(
      {
        templateNewName: { required }
      },
      { templateNewName: templateNewName }
    );
    const v$ = useVuelidate(
      {
        type: { required }
      },
      { type: form.value.type }
    );

    const {
      insuranceInfo,
      insurancePremium,
      withInsurance,
      orderGuid,
      insCalc,
      insReqLoading
    } = useInsuranceCalc(form.value as OrderItemInterface);

    const {
      create: createOrder,
      createOrderWithInsurance,
      loadingProgress,
      reqMessage,
      reqLoading,
      reqStatus,
      saveTemplateAs,
      lastSaveOperation,
      applySelectedTemplate
    } = useOrderCreation(v$, {
      order: form as Ref<OrderItemInterface>,
      insurancePremium,
      withInsurance,
      orderGuid,
      insuranceInfo
    });

    const isLoading = computed(() => reqLoading.value || insReqLoading.value);

    const onTypeInput = (value: string) => {
      if (
        (form.value.type == "Ftl" || form.value.type == "Fcl") &&
        (value == "Ftl" || value == "Fcl")
      ) {
        form.value.type = value;
        return;
      }

      form.value = new OrderItemModel();
      form.value.type = value;
    };

    const onSaveTemplateChoiceDialog = async () => {
      const template = form.value.template;
      if (!template) return;

      await saveTemplateAs({
        id: template.id,
        name: template.name
      });

      showSaveTemplateChoiceDialog.value = false;
    };

    const onSaveAsSaveTemplateChoiceDialog = async () => {
      showSaveTemplateChoiceDialog.value = false;

      templateNewId.value = form.value.template?.id ?? null;
      showEditTemplateNameDialog.value = true;
    };

    const onConfirmEditTemplateNameDialog = async () => {
      const template = form.value.template;

      vTemplate$.value.templateNewName.$touch();

      if (vTemplate$.value.templateNewName.$invalid) return;

      await saveTemplateAs({
        id:
          template?.name == templateNewName.value ? templateNewId.value : null,
        name: templateNewName.value
      });
      showEditTemplateNameDialog.value = false;
    };

    const onCancelEditTemplateNameDialog = async () => {
      showEditTemplateNameDialog.value = false;
    };

    const createTemplate = () => {
      if (form.value.template) showSaveTemplateChoiceDialog.value = true;
      else {
        showEditTemplateNameDialog.value = true;
      }
    };

    const selectTemplate = async (
      selectedTemplate: TemplateFormInterface | null
    ) => {
      await applySelectedTemplate(selectedTemplate);

      form.value.template = selectedTemplate;
    };

    return {
      form,
      insurancePremium,
      isLoading,
      loadingProgress,
      reqMessage,
      reqStatus,
      v$,
      vTemplate$,
      createOrder,
      createOrderWithInsurance,
      insCalc,
      onTypeInput,
      createTemplate,
      showSaveTemplateChoiceDialog,
      onSaveTemplateChoiceDialog,
      onSaveAsSaveTemplateChoiceDialog,
      showEditTemplateNameDialog,
      onConfirmEditTemplateNameDialog,
      onCancelEditTemplateNameDialog,
      templateNewName,
      lastSaveOperation,
      selectTemplate
    };
  },
  computed: {
    summary() {
      const loadingAddresses: string[] = [];
      const unloadingAddresses: string[] = [];

      this.form.places.forEach(place => {
        if (place.loadingAddress.address.value != "")
          loadingAddresses.push(place.loadingAddress.address.value);
        if (place.unloadingAddress.address.value != "")
          unloadingAddresses.push(place.unloadingAddress.address.value);
      });

      const loadingAddressesCountString = plural(
        uniq(loadingAddresses).length,
        "адрес погрузки",
        "адреса погрузки",
        "адресов погрузки",
        true
      );
      const unloadingAddressesCountString = plural(
        uniq(unloadingAddresses).length,
        "адрес выгрузки",
        "адреса выгрузки",
        "адресов выгрузки",
        true
      );

      return `${loadingAddressesCountString}, ${unloadingAddressesCountString}`;
    }
  }
});
