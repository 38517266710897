












































import { defineComponent, PropType, watch } from "vue";
import BaseInput from "@/components/UI/BaseInput.vue";
import draggable from "vuedraggable";
import useRoute from "@/components/order/Route/compositions/useRoute";
import { OrderItemInterface } from "@/models/order/OrderItem.interface";

export default defineComponent({
  name: "Route",
  components: {
    BaseInput,
    draggable
  },
  props: {
    id: {
      type: String as PropType<string>,
      default: ""
    },
    order: {
      type: Object as PropType<OrderItemInterface>,
      required: true
    }
  },
  setup(props) {
    const {
      v$,
      points,
      errorMessage,
      isInvalidPoint,
      mapPlaceToSortedPoints,
      validatePoints,
      onDragEnd
    } = useRoute(props.order);

    watch(
      () => props.order.places,
      () => {
        mapPlaceToSortedPoints(props.order.places);
        validatePoints();
      },
      {
        immediate: true,
        deep: true
      }
    );

    return {
      v$,
      points,
      errorMessage,
      onDragEnd,
      isInvalidPoint
    };
  }
});
