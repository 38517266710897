










































































import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  toRefs
} from "vue";
import useVuelidate from "@vuelidate/core";
import { TemplateFormInterface } from "@/models/store/TemplatesTableHeader.interface";
import { useUserStore } from "@/store/useUserStore";

export default defineComponent({
  components: {},
  props: {
    id: {
      type: String as PropType<string>,
      default: ""
    },
    template: {
      type: Object as PropType<TemplateFormInterface | null>,
      default: null
    }
  },
  emits: ["select-template"],
  setup(props, { emit }) {
    const { template } = toRefs(props);
    const userStore = useUserStore();

    const selectedTemplate = ref<TemplateFormInterface | null>(null);
    const templatesLoading = ref<boolean>(false);
    const showUseTemplateDialog = ref<boolean>(false);

    const noDataMessage = computed<string>(() => {
      return "Шаблонов не найдено";
    });
    const templates = computed(() => {
      return userStore.templates;
    });

    const v$ = useVuelidate({}, template);

    const onConfirmUseTemplateDialog = async () => {
      showUseTemplateDialog.value = false;
      emit("select-template", selectedTemplate.value);
    };

    const onCancelUseTemplateDialog = async () => {
      selectedTemplate.value = template.value;
      showUseTemplateDialog.value = false;
    };

    const loadTemplates = async () => {
      templatesLoading.value = true;

      await userStore.getTemplates();

      templatesLoading.value = false;
    };
    const onTemplateSelect = (value: TemplateFormInterface | string) => {
      if (!value) return;

      // showUseTemplateDialog.value = true;
      emit("select-template", selectedTemplate.value);
    };

    onMounted(() => {
      loadTemplates();
    });

    return {
      templatesLoading,
      noDataMessage,
      v$,
      templates,
      selectedTemplate,
      showUseTemplateDialog,
      loadTemplates,
      onTemplateSelect,
      onConfirmUseTemplateDialog,
      onCancelUseTemplateDialog
    };
  }
});
